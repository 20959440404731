<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <!-- <AppConfig
      :layoutMode="layoutMode"
      @layout-change="onLayoutChange"
      @change-theme="changeTheme"
    /> -->
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import axios from "axios";
export default {
  emits: ["change-theme"],
  data() {
    return {
      permissionNames: [],
      user: {},
      token: "",
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Tableau de bord",
          items: [
            {
              label: "ACCUEIL",
              icon: "coffee",
              to: "/",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    logout() {
      axios.post("logout").then((response) => {
        console.log(response);
        localStorage.removeItem("permissionNames");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.permissionNames = [];
        this.user = {};
        this.token = "";
      });
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit("change-theme", event);
    },
  },

  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? "images/logo.jpeg" : "images/logo.jpeg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    // AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
  mounted() {
    if (localStorage.token) {
      this.permissionNames = JSON.parse(localStorage.permissionNames);
      this.user = JSON.parse(localStorage.user);
      this.token = localStorage.token;
      if (this.permissionNames.includes("Liste commande")) {
        this.menu[0].items.push({
          label: "Commandes",
          icon: "client.svg",
          to: "/orderlist",
        });
      }
      if (this.permissionNames.includes("Liste commande")) {
        this.menu[0].items.push({
          label: "Commandes fournisseur",
          icon: "client.svg",
          to: "/fournisseurorder",
        });
      }
      if (this.permissionNames.includes("Liste retour")) {
        this.menu[0].items.push({
          label: "Retour",
          icon: "client.svg",
          to: "/retour",
        });
      }
      if (this.permissionNames.includes("Liste stock camion")) {
        this.menu[0].items.push({
          label: "Stock par camion",
          icon: "client.svg",
          to: "/camion",
        });
      }
      if (this.permissionNames.includes("Ajouter client")) {
        this.menu[0].items.push({
          label: "Clients",
          icon: "client.svg",
          to: "/client",
        });
      }
      if (this.permissionNames.includes("Liste fournisseur")) {
        this.menu[0].items.push({
          label: "Fournisseurs",
          icon: "client.svg",
          to: "/fournisseur",
        });
      }

      if (this.permissionNames.includes("Liste utilisateur")) {
        this.menu[0].items.push({
          label: "Utilisateurs",
          icon: "/utilsateurs.svg",
          to: "/user",
        });
      }
      if (this.permissionNames.includes("Liste objectif")) {
        this.menu[0].items.push({
          label: "Gestion des objectifs",
          icon: "/utilsateurs.svg",
          to: "/objectif",
        });
      }
      if (this.permissionNames.includes("Liste produit")) {
        this.menu[0].items.push({
          label: "Produit",
          icon: "/utilsateurs.svg",
          to: "/produit",
        });
      }
      if (this.permissionNames.includes("Liste categorie")) {
        this.menu[0].items.push({
          label: "Catégorie",
          icon: "/utilsateurs.svg",
          to: "/categorie",
        });
      }
      this.menu[0].items.push({
        label: "Paramètres de profil",
        icon: "Setting.svg",
        to: "/editprofile",
      });
      if (this.permissionNames.includes("Gestion roles")) {
        this.menu[0].items.push({
          label: "Gestion des roles ",
          icon: "pi pi-fw pi-user-edit",
          to: "/roles",
        });
      }

      axios.defaults.headers.common["Authorization"] = "Bearer" + this.token;
    }
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
