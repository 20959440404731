<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Fournisseur </span>
            <div class="text-900 font-medium text-xl">
              {{ countFournisseur }}
            </div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-purple-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-comment text-purple-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3" v-if="showclientcoutn">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Clients</span>
            <div class="text-900 font-medium text-xl">{{ countClient }}</div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Commande en cours de Livraison</span
            >
            <div class="text-900 font-medium text-xl">
              {{ countCommandeEnCourLivre }}
            </div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Commande livré</span>
            <div class="text-900 font-medium text-xl">
              {{ countCommandeLivre }}
            </div>
          </div>
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-cyan-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-inbox text-cyan-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toolbar class="mb-4">
    <template v-slot:start>
      <Button
        type="button"
        label="Filtre"
        icon="pi pi-filter"
        @click="toggle"
        class="p-button-info"
      />
      <OverlayPanel
        ref="op"
        appendTo="body"
        :showCloseIcon="true"
        style="width: 600px"
      >
        <div class="card">
          <h5>Date</h5>
          <Calendar v-model="datefilter" selectionMode="range" />

          <Divider />
          <h5>Caissier</h5>
          <Dropdown
            v-model="selectedCiasse"
            :options="ciasses"
            optionLabel="full_name"
            placeholder="Select a City"
          />

          <Divider />
          <h5>Client</h5>
          <Dropdown
            v-model="selectedclient"
            :options="clients"
            optionLabel="rsocial"
            placeholder="Select a City"
          />

          <Divider />
          <h5>statut de la commande</h5>

          <div class="p-fluid formgrid grid">
            <div
              v-for="status in statuts"
              :key="status"
              class="field col-12 md:col-6"
            >
              <div>
                <Checkbox
                  id="checkOption1"
                  name="option"
                  :value="status.id"
                  v-model="statusValue"
                />
                <label for="checkOption1">{{ status.nomStatut }}</label>
              </div>
            </div>
          </div>
          <Button
            label="appliquer"
            @click="filter"
            class="mr-2 mb-2 p-button-info"
          ></Button>
          <Button
            label="Initialiser"
            class="mr-2 mb-2"
            @click="initfilterP"
          ></Button>
        </div>
      </OverlayPanel>
    </template>
  </Toolbar>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h5>Vente par date</h5>
        <Chart type="line" :data="basicData" :options="basicOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      showclientcoutn: false,
      countCommandeLivre: 0,
      countCommandeEnCourLivre: 0,
      countClient: 0,
      countFournisseur: 0,
      ciasses: null,
      selectedCiasse: { id: null, full_name: "Tout" },
      statuts: null,
      stat: null,
      datedeb: null,
      datefin: null,
      clients: null,
      selectedclient: { id: null, rsocial: "Tout" },
      statusValue: null,
      datefilter: [],

      basicData: {
        labels: [],
        datasets: [
          {
            label: "Commandes",
            data: [],
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
          },
        ],
      },

      basicOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  created() {
    if (localStorage.token == "" || localStorage.token == null) {
      this.$router.push("/login");
    } else {
      this.countDashboard();
    }
  },
  mounted() {
    if (localStorage.role_id != 3) {
      this.showclientcoutn = true;
    }
  },

  methods: {
    initfilterP() {
      this.datefilter = [];
      this.selectedCiasse = { id: null, full_name: "Tout" };
      this.selectedclient = { id: null, rsocial: "Tout" };
      this.statusValue = null;
    },
    filter() {
      this.loading1 = true;
      console.log(this.datefilter);
      console.log("selectedclient", this.selectedclient);
      console.log("selectedclient", this.statusValue);
      console.log("selectedclient", this.selectedCiasse);

      moment(this.dateEx).format("YYYY-MM-DD HH:mm:ss");
      var filter = {
        user_passe_commande_id: null,
        client_id: null,
        status: null,
        dateD: null,
        dateF: null,
      };
      if (this.selectedCiasse) {
        filter.user_passe_commande_id = this.selectedCiasse.id;
      } else {
        filter.user_passe_commande_id = null;
      }
      if (this.selectedclient.id) {
        filter.client_id = this.selectedclient.id;
      } else {
        filter.client_id = null;
      }
      if (this.statusValue) {
        filter.status = this.statusValue;
      } else {
        filter.status = null;
      }
      if (this.datefilter.length > 0) {
        this.dateD = moment(this.datefilter[0]).format("YYYY-MM-DD");
        this.dateF = this.dateD ;
        if (this.datefilter[1] != null) {
        this.dateF = moment(this.datefilter[1]).format("YYYY-MM-DD");
        console.log(this.datefilter)
      }
        
      } else {
        this.dateD = null;
        this.dateF = null;
      }
      
      filter.dateD=this.dateD;
      filter.dateF=this.dateF;

      axios.post("filterVent", filter).then((response) => {
        console.log(response);
        this.countCommandeLivre = response.data.countCommandeLivre;
        this.countCommandeEnCourLivre = response.data.countCommandeEnCourLivre;
        this.countClient = response.data.countClient;
        this.countFournisseur = response.data.countFournisseur;
        this.basicData.labels = response.data.labels;
        this.basicData.datasets[0].data = response.data.dataset;
      }).catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
      this.$refs.op.hide();
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    countDashboard() {
      axios.post("filterVent").then((response) => {
        this.ciasses = response.data.users;
        this.ciasses.unshift({ id: null, full_name: "Tout" });
        this.statuts = response.data.statu;
        this.clients = response.data.client;
        this.clients.unshift({ id: null, rsocial: "Tout" });
        console.log(response);
        this.countCommandeLivre = response.data.countCommandeLivre;
        this.countCommandeEnCourLivre = response.data.countCommandeEnCourLivre;
        this.countClient = response.data.countClient;
        this.countFournisseur = response.data.countFournisseur;
        this.basicData.labels = response.data.labels;
        this.basicData.datasets[0].data = response.data.dataset;
        console.log(
          "this.basicData.datasets.data",
          this.basicData.datasets[0].data
        );
      }).catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
  },
};
</script>